<template>
  <div id="loginPage">
    <vue-progress-bar class="vueProgressBar"></vue-progress-bar>
    <div class="container">
      <div class="row justify-content-center vh-100">
        <div class="col-lg-10 col-md-10 col-12 align-self-center">
          <div class="card">
            <div class="card-body login-card-body p-0 m-0">
              <div class="row">
                <div class="col-lg-5 col-md-5 col-12 py-5 px-5">
                  <div class="logoBox text-center">
                    <img class="h100" src="../../assets/img/logo.png" />
                  </div>

                  <div class="mt-5">
                    <form>
                      <div class="mb-3 d-flex">
                        <input
                          type="text"
                          class="form-control mr-2 text-center"
                          v-model="code.number1"
                          tabindex="0"
                          @keyup="fnKeyUp"
                          maxlength="1"
                        />
                        <input
                          type="text"
                          class="form-control mr-2 text-center"
                          v-model="code.number2"
                          tabindex="1"
                          @keyup="fnKeyUp"
                          maxlength="1"
                        />
                        <input
                          type="text"
                          class="form-control mr-2 text-center"
                          v-model="code.number3"
                          tabindex="2"
                          @keyup="fnKeyUp"
                          maxlength="1"
                        />
                        <input
                          type="text"
                          class="form-control mr-2 text-center"
                          v-model="code.number4"
                          tabindex="3"
                          @keyup="fnKeyUp"
                          maxlength="1"
                        />
                        <input
                          type="text"
                          class="form-control mr-2 text-center"
                          v-model="code.number5"
                          tabindex="4"
                          @keyup="fnKeyUp"
                          maxlength="1"
                        />
                        <input
                          type="text"
                          class="form-control text-center"
                          v-model="code.number6"
                          tabindex="5"
                          @keyup="fnKeyUp"
                          maxlength="1"
                        />
                      </div>

                      <div class="icheck-primary">
                        <input
                          type="checkbox"
                          id="remember"
                          v-model="code.remember_me"
                        />
                        <label for="remember" class="pl400 fw-500"
                          >Remember Me 14 days</label
                        >
                      </div>
                    </form>
                  </div>
                  <div class="row">
                    <div class="col-8"></div>
                    <div class="col-4">
                      <button
                        type="button"
                        class="btn btn-info btn-block"
                        tabindex="7"
                        @click="fnLogin"
                      >
                        <b-spinner
                          small
                          v-if="loading"
                          class="mr-2"
                        ></b-spinner>
                        Send
                      </button>
                    </div>
                  </div>

                  <div class="mt-3">
                    <p class="mb-0">
                      <a
                        href="javascript:void(0);"
                        class="text-center"
                        @click="fnReSendMfaCode()"
                        >Re-send code
                        <b-spinner
                          small
                          v-if="loadingReSendCode"
                          class="mr-2"
                        ></b-spinner
                      ></a>
                    </p>
                  </div>
                </div>
                <div
                  class="col-lg-7 col-md-7 col-12 bg-success"
                  id="loginBoxImg"
                >
                  <div class="mt2500 ml800">
                    <h4 class="mb1000">Verify Your Account</h4>
                    <div class="d-flex flex-row mb-2">
                      <div class="">
                        Thank you for signing up with CV Screen!
                      </div>
                    </div>
                    <div class="d-flex flex-row mb-2">
                      <div class="">
                        Before proceeding, please activate your account within
                        the next 30 minutes by entering the 6-digit code sent to
                        your registered phone number.
                      </div>
                    </div>
                    <div class="d-flex flex-row mb-2">
                      <div class="">
                        If the code doesn't work, you can request a new one by
                        clicking the "Re-send Code" button.
                      </div>
                    </div>
                    <div class="d-flex flex-row mb-2">
                      <div class="">
                        We appreciate your cooperation in maintaining the
                        security of your account. Feel free to contact us
                        <a
                          href="https://cvscreen.com.au/contact-us/"
                          target="_blank"
                          >here</a
                        >
                        for any assistance.
                      </div>
                    </div>
                    <div class="d-flex flex-row mb-2">
                      <div class="">
                        Thank you for your commitment to account security.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";

export default {
  name: "Verify2FA",
  components: {},

  data() {
    return {
      code: {
        number1: "",
        number2: "",
        number3: "",
        number4: "",
        number5: "",
        number6: "",
        remember_me: 0,
      },
      errors: {},
      loading: false,
      loadingReSendCode: false,
    };
  },
  computed: {
    makeFullNumber() {
      return (
        this.code.number1 +
        this.code.number2 +
        this.code.number3 +
        this.code.number4 +
        this.code.number5 +
        this.code.number6
      );
    },
  },
  methods: {
    fnKeyUp(e) {
      const inputs = Array.from(
        e.target.form.querySelectorAll('input[type="text"]')
      );
      const index = inputs.indexOf(e.target);

      if (index === 5) {
        return "";
      }

      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    fnLogin: async function () {
      try {
        // Progress bar
        this.$Progress.start();
        this.loading = true;

        const response = await authService.verifyCodeMfa({
          code: this.makeFullNumber,
          remember_me: this.code.remember_me,
        });
        this.errors = {};

        // Progress bar
        this.$Progress.finish();

        const { message, status_code } = response?.data;

        if (message === "Success" && status_code === 200) {
          const getToken = authService.getAccessTokenAll();

          if (
            getToken.token_scope === "admin" ||
            getToken.token_scope === "staff"
          ) {
            this.$router.push({ name: "dashboardAdmin" });
          } else if (getToken.token_scope === "individual-user") {
            this.$router.push({ name: "dashboardIU" });
          } else if (getToken.token_scope === "corporate-manager") {
            this.$router.push({ name: "dashboardCM" });
          } else if (getToken.token_scope === "corporate-staff") {
            this.$router.push({ name: "dashboardCM" });
          } else if (getToken.token_scope === "corporate-customer") {
            this.$router.push({ name: "dashboardCC" });
          } else if (getToken.token_scope === "partner") {
            this.$router.push({ name: "dashboardPartner" });
          } else if (getToken.token_scope === "partner-staff") {
            this.$router.push({ name: "dashboardPartner" });
          }
        }
      } catch (error) {
        console.log(error);
        switch (error.response.status) {
          case 505:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "2FA Verification code",
              error.response.data.message
            );

            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "2FA Verification code",
              "Something wrong, May be wrong code or Expired code. Please try again'"
            );
            break;
        }

        this.$Progress.fail();
      } finally {
        this.loading = false;
      }
    },
    fnReSendMfaCode: async function () {
      try {
        // Progress bar
        this.$Progress.start();
        this.loadingReSendCode = true;

        const response = await authService.resendCodeMfa();
        const { message } = response.data;

        if (message === "success") {
          messageService.fnSweetAlertSuccessToast(
            "MFA Verification code",
            "We have sent a new code, Please check your mobile"
          );
        }
        this.errors = {};

        // Progress bar
        this.$Progress.finish();
      } catch (error) {
        console.log(error);
        messageService.fnSweetAlertErrorToast(
          "MFA Verification code",
          "Something wrong, Please start for login page"
        );
        this.$Progress.fail();
      } finally {
        this.loadingReSendCode = false;
      }
    },
  },
};
</script>
